import {
  useForm,
  Form,
  Input,
  Edit,
  Upload,
  useTranslate,
} from "@pankod/refine";
import { API_URL, TOKEN_KEY } from "../../constants";
import { ICategory } from "interfaces";
import {
  getValueProps,
  mediaUploadMapper,
  useStrapiUpload,
} from "@pankod/refine-strapi";

export const CategoryEdit: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<ICategory>();
  const translate = useTranslate();

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          return (
            formProps.onFinish && formProps.onFinish(mediaUploadMapper(values))
          );
        }}
      >
        <Form.Item label="Categoria" name="titulo">
          <Input />
        </Form.Item>
        <Form.Item label="Imagen">
          <Form.Item
            name="imagen"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              listType="picture"
              {...uploadProps}
              headers={{
                Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              }}
            >
              <p className="ant-upload-text">
                {translate("dragAndDrop.dragAndDropBox.title")}
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Edit>
  );
};
