import {
  useForm,
  Form,
  Input,
  Edit,
  Upload,
  useTranslate,
  Space,
  Button,
  Icons,
  useNavigation,
} from "@pankod/refine";
import { API_URL, TOKEN_KEY } from "../../constants";
import { IProductColors } from "interfaces";
import {
  getValueProps,
  mediaUploadMapper,
  useStrapiUpload,
} from "@pankod/refine-strapi";

export const ColorEdit: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IProductColors>({
    redirect: "show",
    metaData: {
      publicationState: "preview",
    },
  });

  const translate = useTranslate();

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 5,
  });

  const record = queryResult?.data?.data;
  const { BarsOutlined } = Icons;

  const { push } = useNavigation();

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        extra: (
          <Space>
            {record?.producto && (
              <Button
                icon={<BarsOutlined />}
                onClick={() => push(`/productos/show/${record?.producto.id}`)}
              >
                Producto
              </Button>
            )}

            {/* <Button
              icon={<BarsOutlined />}
              onClick={() => push(`/productos/show/${record?.producto.id}`)}
            >
              Producto
            </Button>
            <EditButton />
            <RefreshButton metaData={{ publicationState: "preview" }} /> */}
          </Space>
        ),
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          return (
            formProps.onFinish && formProps.onFinish(mediaUploadMapper(values))
          );
        }}
      >
        <Form.Item label="Sku" name="sku">
          <Input />
        </Form.Item>
        <Form.Item label="Color" name="color">
          <Input />
        </Form.Item>
        <Form.Item label="Imagen">
          <Form.Item
            name="imagen"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              listType="picture"
              {...uploadProps}
              headers={{
                Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              }}
            >
              <p className="ant-upload-text">
                {translate("dragAndDrop.dragAndDropBox.title")}
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Edit>
  );
};
