import {
  Show,
  useShow,
  Typography,
  Row,
  Col,
  ImageField,
  Space,
  Button,
  ListButton,
  EditButton,
  RefreshButton,
  useUpdate,
} from "@pankod/refine";
import { ISlideshow } from "interfaces";
import { API_URL } from "../../constants";

const { Title, Text } = Typography;

export const SlideshowShow = () => {
  const { queryResult } = useShow({
    metaData: {
      publicationState: "preview",
    },
  });
  const { data, isLoading, refetch } = queryResult;
  let record = data?.data;

  const { mutate } = useUpdate<ISlideshow>();

  const publish = (id?: string) => {
    if (!id) return;
    if (record?.published_at) {
      mutate(
        {
          resource: "slideshows",
          id: id,
          values: { published_at: null },
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    } else {
      const date = new Date();
      mutate(
        {
          resource: "slideshows",
          id: id,
          values: { published_at: date },
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    }
  };

  return (
    <Show
      isLoading={isLoading}
      pageHeaderProps={{
        extra: (
          <Space>
            <Button type="default" onClick={() => publish(record?.id)}>
              {record?.published_at ? "Anular publicación" : "Publicar"}
            </Button>
            <ListButton />
            <EditButton />
            <RefreshButton metaData={{ publicationState: "preview" }} />
          </Space>
        ),
      }}
    >
      <Row>
        <Col lg={11} xs={22} style={{ marginBottom: 15 }}>
          <Title level={5}>Url</Title>
          <Text>{record?.url}</Text>
        </Col>
        <Col lg={11} xs={22} style={{ marginBottom: 15 }}>
          <Title level={5}>Imagen</Title>
          {record?.imagen && (
            <ImageField
              value={`${API_URL}/${record?.imagen.url}`}
              title={record?.imagen.alternativeText}
              width={200}
            />
          )}
        </Col>
      </Row>
    </Show>
  );
};
