import React, { useState } from "react";
import {
  Col,
  Create,
  Form,
  Input,
  InputNumber,
  IResourceComponentsProps,
  Row,
  Select,
  Switch,
  Upload,
  useApiUrl,
  useForm,
  useSelect,
  useTranslate,
} from "@pankod/refine";

import {
  useStrapiUpload,
  mediaUploadMapper,
  getValueProps,
} from "@pankod/refine-strapi";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { TOKEN_KEY } from "../../constants";
import { IBrand, ICategory } from "interfaces";

export const ProductCreate: React.FC<IResourceComponentsProps> = () => {
  const API_URL = useApiUrl();

  const { formProps, saveButtonProps } = useForm({
    redirect: "show",
  });

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  const translate = useTranslate();

  const [selectedTabShort, setSelectedTabShort] = useState<"write" | "preview">(
    "write"
  );
  const [selectedTabLong, setSelectedTabLong] = useState<"write" | "preview">(
    "write"
  );
  const [selectedTabSpecifications, setSelectedTabSpecifications] = useState<
    "write" | "preview"
  >("write");

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categorias",
    optionLabel: "titulo",
    optionValue: "id",
  });

  const { selectProps: brandSelectProps } = useSelect<IBrand>({
    resource: "marcas",
    optionLabel: "marca",
    optionValue: "id",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          const draftMode = { ...values, published_at: null };
          return (
            formProps.onFinish &&
            formProps.onFinish(mediaUploadMapper(draftMode))
          );
        }}
      >
        <Form.Item label="Titulo" name="titulo">
          <Input />
        </Form.Item>
        <Form.Item
          label="Marca"
          name={["marca", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...brandSelectProps} />
        </Form.Item>
        <Form.Item
          label="Categoria"
          name={["categoria", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...categorySelectProps} />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item label="Precio" name="precio">
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => {
                  return Number(value)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Precio Promo" name="precio_promo">
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => {
                  return Number(value)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Precio Efectivo" name="precio_efectivo">
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => {
                  return Number(value)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item label="Treding" name="trending">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Nuevo" name="nuevo">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Oferta" name="oferta">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Creativos" name="creativos">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Gadgets" name="gadgets">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Segunda Mano" name="segunda_mano">
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Imagen">
          <Form.Item
            name="imagen"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              }}
              listType="picture"
              multiple
              {...uploadProps}
            >
              <p className="ant-upload-text">
                {translate("dragAndDrop.dragAndDropBox.title")}
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Descripción Corta"
          name="descripcion_corta"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <ReactMde
            toolbarCommands={[["header", "bold", "italic"]]}
            selectedTab={selectedTabShort}
            onTabChange={setSelectedTabShort}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
        <Form.Item label="Descripción Larga" name="descripcion_larga">
          <ReactMde
            toolbarCommands={[["header", "bold", "italic"]]}
            selectedTab={selectedTabLong}
            onTabChange={setSelectedTabLong}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
        <Form.Item label="Especificaciones" name="especificaciones">
          <ReactMde
            toolbarCommands={[["header", "bold", "italic"]]}
            selectedTab={selectedTabSpecifications}
            onTabChange={setSelectedTabSpecifications}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
