import React from "react";
import {
  Create,
  Form,
  Input,
  IResourceComponentsProps,
  Upload,
  useApiUrl,
  useForm,
  useTranslate,
} from "@pankod/refine";

import {
  useStrapiUpload,
  mediaUploadMapper,
  getValueProps,
} from "@pankod/refine-strapi";

import { TOKEN_KEY } from "../../constants";

export const SlideshowCreate: React.FC<IResourceComponentsProps> = () => {
  const API_URL = useApiUrl();

  const { formProps, saveButtonProps } = useForm({
    redirect: "show",
  });

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  const translate = useTranslate();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          const draftMode = { ...values, published_at: null };
          return (
            formProps.onFinish &&
            formProps.onFinish(mediaUploadMapper(draftMode))
          );
        }}
      >
        <Form.Item label="Url" name="url">
          <Input />
        </Form.Item>
        <Form.Item label="Imagen">
          <Form.Item
            name="imagen"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              }}
              listType="picture"
              multiple
              {...uploadProps}
            >
              <p className="ant-upload-text">
                {translate("dragAndDrop.dragAndDropBox.title")}
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  );
};
