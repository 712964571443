import { Show, useShow, Typography, Row, Col } from "@pankod/refine";

const { Title, Text } = Typography;

export const BrandShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Row>
        <Col>
          <Title level={5}>Marca</Title>
          <Text>{record?.marca}</Text>
        </Col>
      </Row>
    </Show>
  );
};
