import {
  List,
  DateField,
  Table,
  useTable,
  DeleteButton,
  Space,
  ShowButton,
  EditButton,
} from "@pankod/refine";

import { IBrand } from "../../interfaces";

export const BrandList: React.FC = () => {
  const { tableProps } = useTable<IBrand>({});

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="marca" title="Marca" />
        <Table.Column
          dataIndex="created_at"
          title="Creado"
          render={(value) => (
            <DateField format="DD-MM-YYYY HH:MM" value={value} />
          )}
        />
        <Table.Column<IBrand>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <ShowButton size="small" recordItemId={record.id} hideText />
                <EditButton size="small" recordItemId={record.id} hideText />
                <DeleteButton size="small" recordItemId={record.id} hideText />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
