import {
  // Col,
  Create,
  Form,
  Input,
  // InputNumber,
  IResourceComponentsProps,
  // Row,
  // Select,
  // Switch,
  Upload,
  useApiUrl,
  useForm,
  // useSelect,
  useTranslate,
} from "@pankod/refine";

import {
  useStrapiUpload,
  mediaUploadMapper,
  getValueProps,
} from "@pankod/refine-strapi";

// import ReactMarkdown from "react-markdown";
// import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { TOKEN_KEY } from "../../constants";

import { useHistory } from "react-router-dom";

export const ColorCreate: React.FC<IResourceComponentsProps> = () => {
  const API_URL = useApiUrl();

  const { formProps, saveButtonProps } = useForm({
    redirect: "show",
  });

  const {
    location: { state },
  } = useHistory();

  console.log(state);

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 5,
  });

  const translate = useTranslate();

  // const [selectedTabShort, setSelectedTabShort] = useState<"write" | "preview">(
  //   "write"
  // );
  // const [selectedTabLong, setSelectedTabLong] = useState<"write" | "preview">(
  //   "write"
  // );
  // const [selectedTabSpecifications, setSelectedTabSpecifications] = useState<
  //   "write" | "preview"
  // >("write");

  // const { selectProps: categorySelectProps } = useSelect<ICategory>({
  //   resource: "categorias",
  //   optionLabel: "titulo",
  //   optionValue: "id",
  // });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          const draftMode = { ...values, published_at: null, producto: state };
          return (
            formProps.onFinish &&
            formProps.onFinish(mediaUploadMapper(draftMode))
          );
        }}
      >
        <Form.Item label="Sku" name="sku">
          <Input />
        </Form.Item>
        <Form.Item label="Color" name="color">
          <Input />
        </Form.Item>
        <Form.Item label="Imagen">
          <Form.Item
            name="imagen"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              headers={{
                Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              }}
              listType="picture"
              multiple
              {...uploadProps}
            >
              <p className="ant-upload-text">
                {translate("dragAndDrop.dragAndDropBox.title")}
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  );
};
