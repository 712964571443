import {
  Show,
  useShow,
  Typography,
  Row,
  Col,
  ImageField,
  Space,
  Button,
  EditButton,
  RefreshButton,
  useUpdate,
  Icons,
  useNavigation,
} from "@pankod/refine";
import { IProductColors } from "interfaces";
import { API_URL } from "../../constants";

const { Title, Text } = Typography;

export const ColorShow = () => {
  const { queryResult } = useShow({
    metaData: {
      publicationState: "preview",
    },
  });
  const { data, isLoading, refetch } = queryResult;
  let record = data?.data;

  const { mutate } = useUpdate<IProductColors>();

  const publish = (id?: string) => {
    if (!id) return;
    if (record?.published_at) {
      mutate(
        {
          resource: "colores",
          id: id,
          values: { published_at: null },
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    } else {
      const date = new Date();
      mutate(
        {
          resource: "colores",
          id: id,
          values: { published_at: date },
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    }
  };

  const { push } = useNavigation();

  const { BarsOutlined } = Icons;

  return (
    <Show
      isLoading={isLoading}
      pageHeaderProps={{
        extra: (
          <Space>
            <Button type="default" onClick={() => publish(record?.id)}>
              {record?.published_at ? "Anular publicación" : "Publicar"}
            </Button>
            <Button
              icon={<BarsOutlined />}
              onClick={() => push(`/productos/show/${record?.producto.id}`)}
            >
              Producto
            </Button>
            <EditButton />
            <RefreshButton metaData={{ publicationState: "preview" }} />
          </Space>
        ),
      }}
    >
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Title level={5}>Sku</Title>
          <Text>{record?.sku}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Color</Title>
          <Text>{record?.color}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Title level={5}>Imagenes</Title>
          {record?.imagen.map((item: any, key: any) => {
            return (
              <ImageField
                value={`${API_URL}/${item.url}`}
                title={item.alternativeText}
                width={200}
                key={key}
              />
            );
          })}
        </Col>
      </Row>
    </Show>
  );
};
