import {
  Show,
  useShow,
  Typography,
  Row,
  Col,
  ImageField,
} from "@pankod/refine";
import { API_URL } from "../../constants";

const { Title, Text } = Typography;

export const CategoryShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Row>
        <Col lg={11} xs={22} style={{ marginBottom: 15 }}>
          <Title level={5}>Categoria</Title>
          <Text>{record?.titulo}</Text>
        </Col>
        <Col lg={11} xs={22} style={{ marginBottom: 15 }}>
          <Title level={5}>Imagen</Title>
          {record?.imagen && (
            <ImageField
              value={`${API_URL}/${record?.imagen.url}`}
              title={record?.imagen.alternativeText}
              width={200}
            />
          )}
        </Col>
      </Row>
    </Show>
  );
};
