import {
  Show,
  useShow,
  Typography,
  Row,
  Col,
  ImageField,
  Space,
  Button,
  ListButton,
  EditButton,
  RefreshButton,
  useUpdate,
  BooleanField,
  Icons,
  Table,
  useTable,
  List,
  useNavigation,
  DeleteButton,
} from "@pankod/refine";
import { IProduct, IProductColors } from "interfaces";
import { API_URL } from "../../constants";

const { Title, Text } = Typography;

export const ProductShow = () => {
  const { queryResult } = useShow({
    metaData: {
      publicationState: "preview",
    },
  });
  const { data, isLoading, refetch } = queryResult;
  let record = data?.data;

  const { mutate } = useUpdate<IProduct>();

  const publish = (id?: string) => {
    if (!id) return;
    if (record?.published_at) {
      mutate(
        {
          resource: "productos",
          id: id,
          values: { published_at: null },
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    } else {
      const date = new Date();
      mutate(
        {
          resource: "productos",
          id: id,
          values: { published_at: date },
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    }
  };

  const {
    CloseCircleOutlined,
    CheckCircleOutlined,
    PlusSquareOutlined,
    EyeOutlined,
    EditOutlined,
  } = Icons;

  const { tableProps } = useTable<IProductColors>({
    resource: "colores",
    permanentFilter: [
      {
        field: "producto",
        operator: "eq",
        value: record?.id,
      },
      {
        field: "_publicationState",
        operator: "eq",
        value: "preview",
      },
    ],
  });

  const { push, edit } = useNavigation();

  return (
    <Show
      isLoading={isLoading}
      pageHeaderProps={{
        extra: (
          <Space>
            <Button type="default" onClick={() => publish(record?.id)}>
              {record?.published_at ? "Anular publicación" : "Publicar"}
            </Button>
            <ListButton />
            <EditButton />
            <RefreshButton metaData={{ publicationState: "preview" }} />
          </Space>
        ),
      }}
    >
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Title level={5}>Marca</Title>
          <Text>{record?.marca ? record?.marca.marca : "Sin Marca"}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Categoria</Title>
          <Text>{record?.categoria.titulo}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col>
          <Title level={5}>Titulo</Title>
          <Text>{record?.titulo}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Title level={5}>Precio</Title>
          <Text>{record?.precio}</Text>
        </Col>
        <Col span={8}>
          <Title level={5}>Precio Promo</Title>
          <Text>{record?.precio_promo}</Text>
        </Col>
        <Col span={8}>
          <Title level={5}>Precio Efectivo</Title>
          <Text>{record?.precio_efectivo}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col span={4}>
          <Title level={5}>Trending</Title>
          <Text>
            <BooleanField
              value={record?.trending}
              trueIcon={<CheckCircleOutlined />}
              falseIcon={<CloseCircleOutlined />}
              valueLabelTrue="Si"
              valueLabelFalse="No"
            />
          </Text>
        </Col>
        <Col span={4}>
          <Title level={5}>Nuevo</Title>
          <Text>
            <BooleanField
              value={record?.nuevo}
              trueIcon={<CheckCircleOutlined />}
              falseIcon={<CloseCircleOutlined />}
              valueLabelTrue="Si"
              valueLabelFalse="No"
            />
          </Text>
        </Col>
        <Col span={4}>
          <Title level={5}>Oferta</Title>
          <Text>
            <BooleanField
              value={record?.oferta}
              trueIcon={<CheckCircleOutlined />}
              falseIcon={<CloseCircleOutlined />}
              valueLabelTrue="Si"
              valueLabelFalse="No"
            />
          </Text>
        </Col>
        <Col span={4}>
          <Title level={5}>Creativos</Title>
          <Text>
            <BooleanField
              value={record?.creativos}
              trueIcon={<CheckCircleOutlined />}
              falseIcon={<CloseCircleOutlined />}
              valueLabelTrue="Si"
              valueLabelFalse="No"
            />
          </Text>
        </Col>
        <Col span={4}>
          <Title level={5}>Gadgets</Title>
          <Text>
            <BooleanField
              value={record?.gadgets}
              trueIcon={<CheckCircleOutlined />}
              falseIcon={<CloseCircleOutlined />}
              valueLabelTrue="Si"
              valueLabelFalse="No"
            />
          </Text>
        </Col>
        <Col span={4}>
          <Title level={5}>Segunda mano</Title>
          <Text>
            <BooleanField
              value={record?.segunda_mano}
              trueIcon={<CheckCircleOutlined />}
              falseIcon={<CloseCircleOutlined />}
              valueLabelTrue="Si"
              valueLabelFalse="No"
            />
          </Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col>
          <Title level={5}>Descripción corta</Title>
          <Text>{record?.descripcion_corta}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col>
          <Title level={5}>Descripción larga</Title>
          <Text>{record?.descripcion_larga}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col>
          <Title level={5}>Especificaciones</Title>
          <Text>{record?.especificaciones}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col>
          <Title level={5}>Imagen</Title>
          {record?.imagen && (
            <ImageField
              value={`${API_URL}/${record?.imagen.url}`}
              title={record?.imagen.alternativeText}
              width={200}
            />
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col span={24}>
          <List
            pageHeaderProps={{
              extra: (
                <Space>
                  <Button
                    icon={<PlusSquareOutlined />}
                    onClick={(): void => push("/colores/create", record?.id)}
                  >
                    Nuevo
                  </Button>
                </Space>
              ),
            }}
            resource="colores"
          >
            <Table {...tableProps} rowKey="id">
              {/* <Table.Column dataIndex="id" title="ID" /> */}
              <Table.Column dataIndex="sku" title="Sku" />
              <Table.Column dataIndex="color" title="Color" />
              <Table.Column
                title="Publicado"
                dataIndex="published_at"
                render={(value) => {
                  return (
                    <BooleanField
                      value={value}
                      trueIcon={<CheckCircleOutlined />}
                      falseIcon={<CloseCircleOutlined />}
                      valueLabelTrue="Si"
                      valueLabelFalse="No"
                    />
                  );
                }}
                align="center"
              />
              <Table.Column
                dataIndex="imagen"
                title="Imagenes"
                render={(value) => {
                  // console.log(value);
                  return value.map((item: any, key: any) => {
                    return (
                      <ImageField
                        value={`${API_URL}${item.url}`}
                        title={item.alternativeText}
                        width={100}
                        key={key}
                      />
                    );
                  });
                }}
              />
              <Table.Column<IProductColors>
                title="Acciones"
                dataIndex="id"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <Button
                        icon={<EyeOutlined />}
                        onClick={(): void => push(`/colores/show/${record.id}`)}
                        size="small"
                      />

                      <Button
                        icon={<EditOutlined />}
                        onClick={(): void => edit("colores", record.id)}
                        size="small"
                      />
                      <DeleteButton
                        size="small"
                        recordItemId={record.id}
                        hideText
                        resourceName="colores"
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </Show>
  );
};
