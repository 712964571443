import {
  List,
  // TextField,
  // TagField,
  DateField,
  Table,
  useTable,
  DeleteButton,
  Space,
  ShowButton,
  ImageField,
  EditButton,
} from "@pankod/refine";
import { API_URL } from "../../constants";

import { ICategory } from "../../interfaces";

export const CategoryList: React.FC = () => {
  const { tableProps } = useTable<ICategory>({});

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="titulo" title="Categoria" />
        <Table.Column
          title="Imagen"
          dataIndex="imagen"
          render={(value) => {
            return value ? (
              <ImageField
                value={`${API_URL}/${value.url}`}
                title={value.alternativeText}
                width={100}
              />
            ) : (
              ""
            );
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title="Creado"
          render={(value) => (
            <DateField format="DD-MM-YYYY HH:MM" value={value} />
          )}
        />
        <Table.Column<ICategory>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <ShowButton size="small" recordItemId={record.id} hideText />
                <EditButton size="small" recordItemId={record.id} hideText />
                <DeleteButton size="small" recordItemId={record.id} hideText />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
