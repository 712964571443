import {
  List,
  // TextField,
  // TagField,
  DateField,
  Table,
  useTable,
  DeleteButton,
  Space,
  ShowButton,
  ImageField,
  EditButton,
  BooleanField,
  Icons,
} from "@pankod/refine";
import { API_URL } from "../../constants";

import { ISlideshow } from "../../interfaces";

export const SlideshowList: React.FC = () => {
  const { tableProps } = useTable<ISlideshow>({
    initialFilter: [
      {
        field: "_publicationState",
        operator: "eq",
        value: "preview",
      },
    ],
  });
  const { CloseCircleOutlined, CheckCircleOutlined } = Icons;

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="url" title="Url" />
        <Table.Column
          title="Imagen"
          dataIndex="imagen"
          render={(value) => {
            return value ? (
              <ImageField
                value={`${API_URL}/${value.url}`}
                title={value.alternativeText}
                width={100}
              />
            ) : (
              ""
            );
          }}
        />
        <Table.Column
          title="Publicado"
          dataIndex="published_at"
          render={(value) => {
            return (
              <BooleanField
                value={value}
                trueIcon={<CheckCircleOutlined />}
                falseIcon={<CloseCircleOutlined />}
                valueLabelTrue="Si"
                valueLabelFalse="No"
              />
            );
          }}
          align="center"
        />
        <Table.Column
          dataIndex="created_at"
          title="Creado"
          render={(value) => (
            <DateField format="DD-MM-YYYY HH:MM" value={value} />
          )}
        />
        <Table.Column<ISlideshow>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <ShowButton size="small" recordItemId={record.id} hideText />
                <EditButton size="small" recordItemId={record.id} hideText />
                <DeleteButton size="small" recordItemId={record.id} hideText />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
