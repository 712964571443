import {
  List,
  // TextField,
  // TagField,
  DateField,
  Table,
  useTable,
  DeleteButton,
  Space,
  ShowButton,
  ImageField,
  EditButton,
  BooleanField,
  Icons,
} from "@pankod/refine";
import { truncate } from "functions";
import { API_URL } from "../../constants";

import { IProduct } from "../../interfaces";

export const ProductList: React.FC = () => {
  const { tableProps } = useTable<IProduct>({
    initialFilter: [
      {
        field: "_publicationState",
        operator: "eq",
        value: "preview",
      },
    ],
  });
  const { CloseCircleOutlined, CheckCircleOutlined } = Icons;

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column
          title="Imagen"
          dataIndex="imagen"
          render={(value) => {
            return value ? (
              <ImageField
                value={`${API_URL}/${value.url}`}
                title={value.alternativeText}
                width={100}
              />
            ) : (
              ""
            );
          }}
        />

        <Table.Column dataIndex="titulo" title="Titulo" />
        <Table.Column dataIndex="precio" title="Precio" />
        <Table.Column dataIndex="precio_promo" title="Precio Promo" />
        <Table.Column dataIndex="precio_efectivo" title="Precio Efectivo" />
        <Table.Column
          title="Trending"
          dataIndex="trending"
          render={(value) => {
            return (
              <BooleanField
                value={value}
                trueIcon={<CheckCircleOutlined />}
                falseIcon={<CloseCircleOutlined />}
                valueLabelTrue="Si"
                valueLabelFalse="No"
              />
            );
          }}
          align="center"
        />
        <Table.Column
          title="Nuevo"
          dataIndex="nuevo"
          render={(value) => {
            return (
              <BooleanField
                value={value}
                trueIcon={<CheckCircleOutlined />}
                falseIcon={<CloseCircleOutlined />}
                valueLabelTrue="Si"
                valueLabelFalse="No"
              />
            );
          }}
          align="center"
        />
        <Table.Column
          title="Oferta"
          dataIndex="oferta"
          render={(value) => {
            return (
              <BooleanField
                value={value}
                trueIcon={<CheckCircleOutlined />}
                falseIcon={<CloseCircleOutlined />}
                valueLabelTrue="Si"
                valueLabelFalse="No"
              />
            );
          }}
          align="center"
        />
        <Table.Column
          title="Gadgets"
          dataIndex="gadgets"
          render={(value) => {
            return (
              <BooleanField
                value={value}
                trueIcon={<CheckCircleOutlined />}
                falseIcon={<CloseCircleOutlined />}
                valueLabelTrue="Si"
                valueLabelFalse="No"
              />
            );
          }}
          align="center"
        />
        <Table.Column
          title="Descripción Corta"
          dataIndex="descripcion_corta"
          render={(value) => {
            return truncate(value, 50);
          }}
        />
        <Table.Column
          title="Descripción Larga"
          dataIndex="descripcion_larga"
          render={(value) => {
            return truncate(value, 50);
          }}
        />
        <Table.Column
          title="Especificaciones"
          dataIndex="especificaciones"
          render={(value) => {
            return truncate(value, 50);
          }}
        />
        <Table.Column
          title="Publicado"
          dataIndex="published_at"
          render={(value) => {
            return (
              <BooleanField
                value={value}
                trueIcon={<CheckCircleOutlined />}
                falseIcon={<CloseCircleOutlined />}
                valueLabelTrue="Si"
                valueLabelFalse="No"
              />
            );
          }}
          align="center"
        />
        <Table.Column
          dataIndex="created_at"
          title="Creado"
          render={(value) => (
            <DateField format="DD-MM-YYYY HH:MM" value={value} />
          )}
        />
        <Table.Column<IProduct>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <ShowButton size="small" recordItemId={record.id} hideText />
                <EditButton size="small" recordItemId={record.id} hideText />
                <DeleteButton size="small" recordItemId={record.id} hideText />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
