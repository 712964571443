import {
  List,
  // TextField,
  // TagField,
  DateField,
  Table,
  useTable,
  DeleteButton,
  Space,
  ShowButton,
  ImageField,
  EditButton,
  BooleanField,
  Icons,
} from "@pankod/refine";
import { API_URL } from "../../constants";

import { IProductColors } from "../../interfaces";

export const ColorList: React.FC = () => {
  const { tableProps } = useTable<IProductColors>({
    initialFilter: [
      {
        field: "_publicationState",
        operator: "eq",
        value: "preview",
      },
    ],
  });
  const { CloseCircleOutlined, CheckCircleOutlined } = Icons;

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="sku" title="Sku" />
        <Table.Column dataIndex="color" title="Color" />
        <Table.Column
          title="Imagen"
          dataIndex="imagen"
          render={(value) => {
            return value.map((item: any, key: any) => {
              return (
                <ImageField
                  value={`${API_URL}/${item.url}`}
                  title={item.alternativeText}
                  width={80}
                  key={key}
                />
              );
            });
          }}
        />

        <Table.Column
          title="Publicado"
          dataIndex="published_at"
          render={(value) => {
            return (
              <BooleanField
                value={value}
                trueIcon={<CheckCircleOutlined />}
                falseIcon={<CloseCircleOutlined />}
                valueLabelTrue="Si"
                valueLabelFalse="No"
              />
            );
          }}
          align="center"
        />
        <Table.Column
          dataIndex="created_at"
          title="Creado"
          render={(value) => (
            <DateField format="DD-MM-YYYY HH:MM" value={value} />
          )}
        />
        <Table.Column<IProductColors>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <ShowButton size="small" recordItemId={record.id} hideText />
                <EditButton size="small" recordItemId={record.id} hideText />
                <DeleteButton size="small" recordItemId={record.id} hideText />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
