import { Refine } from "@pankod/refine";
import routerProvider from "@pankod/refine-react-router";

import "@pankod/refine/dist/styles.min.css";
import { DataProvider } from "./Providers/dataProvider";
import strapiAuthProvider from "authProvider";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { useTranslation } from "react-i18next";
import { API_URL } from "./constants";
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CategoryShow,
} from "pages/category";
import { BrandCreate, BrandEdit, BrandList, BrandShow } from "pages/brand";
import {
  SlideshowCreate,
  SlideshowEdit,
  SlideshowList,
  SlideshowShow,
} from "pages/slideshow";
import { ProductCreate, ProductList, ProductShow } from "pages/products";
import { ColorCreate, ColorEdit, ColorList, ColorShow } from "pages/colores";

function App() {
  const { t, i18n } = useTranslation();

  const { authProvider, axiosInstance } = strapiAuthProvider(API_URL);
  const dataProvider = DataProvider(API_URL, axiosInstance);

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <Refine
      routerProvider={routerProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      i18nProvider={i18nProvider}
      resources={[
        {
          name: "categorias",
          list: CategoryList,
          edit: CategoryEdit,
          show: CategoryShow,
          create: CategoryCreate,
        },
        {
          name: "marcas",
          list: BrandList,
          edit: BrandEdit,
          show: BrandShow,
          create: BrandCreate,
        },
        {
          name: "slideshows",
          list: SlideshowList,
          edit: SlideshowEdit,
          show: SlideshowShow,
          create: SlideshowCreate,
        },
        {
          name: "productos",
          list: ProductList,
          edit: SlideshowEdit,
          show: ProductShow,
          create: ProductCreate,
        },
        {
          name: "colores",
          list: ColorList,
          edit: ColorEdit,
          show: ColorShow,
          create: ColorCreate,
        },
      ]}
    ></Refine>
  );
}

export default App;
