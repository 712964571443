import { useForm, Form, Input, Edit } from "@pankod/refine";
import { IBrand } from "interfaces";

export const BrandEdit: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IBrand>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Marca" name="marca">
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
